import Vue from 'vue'
import { Autenticado, logIn } from "../services/autenticacao";
import store_usuario from "../store/store_usuario"
import store_site from "../store/store_site"
import VueRouter from 'vue-router'
import { C_SENHA_SGI } from '../services/constantes';
import { GS_APLICACAO_INICIAL } from "../services/global";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPrincipalContainer',
    component: () => import('../components/Login/LoginPrincipalContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        //console.log('GS_APLICACAO_INICIAL', GS_APLICACAO_INICIAL);
        router.push(GS_APLICACAO_INICIAL);
      }
      else {
        //console.log('GS_APLICACAO_INICIAL ------', GS_APLICACAO_INICIAL);
        next()
      }
    }
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: () => import('../pages/Sobre.vue')
  },
  {
    path: '/empreendimentos',
    name: 'Empreendimentos',
    component: () => import('../components/Empreendimentos/Empreendimentos.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: '/empreendimentoconsulta',
    name: 'EmpreendimentoConsulta',
    component: () => import('../components/Empreendimentos/EmpreendimentoConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: "/empreendimentocontainer/:cod_empreendimento",
    name: "EmpreendimentoContainer",
    component: () =>
      import("../components/Empreendimentos/EmpreendimentoContainer.vue"),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(
          (o) => o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5
        );
        if (ls_Found) next();
        else router.push("/");
      } else {
        router.push("/");
      }
    },
  },
  {
    path: '/mapalotes',
    name: 'MapaLotes',
    component: () => import('../components/EmpreendMapa/MapaLotes.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/mapaedicao',
    name: 'MapaEdicao',
    component: () => import('../components/EmpreendMapa/MapaEdicao.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/mapaenquadramento',
    name: 'MapaEnquadramento',
    component: () => import('../components/EmpreendMapa/MapaEnquadramento.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/empreendimentodetalhe/:cod_empreendimento',
    name: 'EmpreendimentoDetalhe',
    component: () => import('../components/Empreendimentos/EmpreendimentoDetalhe.vue')
  },
  {
    path: '/empreendimentoespelhovenda/:cod_empreendimento',
    name: 'EmpreendimentoEspelhoVenda',
    component: () => import('../components/Empreendimentos/EmpreendimentoEspelhoVenda.vue')
  },
  {
    path: '/favoritos',
    name: 'Favoritos',
    component: () => import('../pages/Favoritos.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/propostaconsulta',
    name: 'PropostaConsulta',
    component: () => import('../components/Propostas/PropostaConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/propostacontainer/:cod_empreendvenda',
    name: 'PropostaContainer',
    component: () => import('../components/Propostas/PropostaContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/contatoconsulta',
    name: 'ContatoConsulta',
    component: () => import('../components/Contatos/ContatoConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/contatocadastro',
    name: 'ContatoCadastro',
    component: () => import('../components/Contatos/ContatoCadastro.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/usuarioconsulta',
    name: 'UsuarioConsulta',
    component: () => import('../components/Usuarios/UsuarioConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/usuariocadastro/:cod_usuario',
    name: 'UsuarioCadastro',
    component: () => import('../components/Usuarios/UsuarioCadastro.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/pessoaconsulta',
    name: 'PessoaConsulta',
    component: () => import('../components/Pessoas/PessoaConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: "/pessoacontainer/:cod_pessoa",
    name: 'PessoaContainer',
    component: () => import('../components/Pessoas/PessoaContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: "/editordocumento",
    name: "EditorDocumento",
    component: () =>
      import("../components/ComponentesGlobais/EditorDocumentos/EditorDocumento.vue"),
    props: (route) => ({
      ACAO: route.query.ACAO,
      ARQUIVO: route.query.ARQUIVO,
      USER_ADDRESS: route.query.USER_ADDRESS,
      TIPO: route.query.TIPO,
      CAMINHO: route.query.CAMINHO,
      URL_API: route.query.URL_API,
      TITULO: route.query.TITULO,
      TIPO_VISUALIZACAO: route.query.TIPO_VISUALIZACAO
    }),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(
          (o) => o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5
        );
        if (ls_Found) next();
        else router.push("/");
      } else {
        router.push("/");
      }
    },
  },
  /*
    {
      path: '/enviodocumentos',
      name: 'EnvioDocumentos',
      component: () => import('../components/ModalEnvioDocumentos/ModalEnvioDocumentos.vue')
    },*/
  {
    path: '/relatorioscomponente',
    name: 'RelatoriosComponente',
    component: () => import('../components/Relatorios/RelatoriosComponente.vue'),
    props: (route) => ({
      cod_relatorio: route.query.cod_relatorio,
      detalhes_: false,
      parametros: route.query.parametros
    })
  },
  // {
  //   path: '/indexrelatorio',
  //   name: 'IndexRelatorio',
  //   component: () => import('../components/Negociacoes/IndexRelatorio.vue')
  // },
  ////++++

  {
    path: '/relatorios',
    name: 'Relatorios',
    component: () => import('../components/Relatorios/Relatorios.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/relatoriosmanutencao',
    name: 'RelatoriosManutencao',
    component: () => import('../components/Relatorios/RelatoriosManutencao.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/paineismanutencao',
    name: 'PaineisManutencao',
    component: () => import('../components/Paineis/PaineisManutencao.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/painel',
    name: 'Painel',
    component: () => import('../pages/Painel.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        next()
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/paineisstcontainer',
    name: 'PaineisSTContainer',
    component: () => import('../components/Paineis/PaineisST/PaineisSTContainer.vue'),
    props: (route) => ({ MENU_NIVEL1: route.query.MENU_NIVEL1 }),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/painelproprietario',
    name: 'PainelProprietario',
    component: () => import('../components/Paineis/PainelProprietario.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/painelcliente',
    name: 'PainelCliente',
    component: () => import('../components/Paineis/PainelCliente/PainelCliente.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 1))
        if (ls_Found) {
          next()
        }
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/extratofinanceiro',
    name: 'ExtratoFinanceiro',
    component: () => import('../components/Paineis/PainelCliente/ExtratoFinanceiro.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 1))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/segundavia',
    name: 'SegundaVia',
    component: () => import('../components/Paineis/PainelCliente/SegundaVia.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 1))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/painelgestor',
    name: 'PainelGestor',
    component: () => import('../components/Paineis/PainelGestor.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/paineladministrador',
    name: 'PainelAdministrador',
    component: () => import('../components/Paineis/PainelAdministrador.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/tasks',
    name: 'NegociacoesTask',
    component: () => import('../components/Negociacoes/NegociacoesTask.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/leadsconsulta',
    name: 'LeadsConsulta',
    component: () => import('../components/Negociacoes/Leads/LeadsConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/qualificacoesconsulta',
    name: 'QualificacoesConsulta',
    component: () => import('../components/Negociacoes/Qualificacoes/QualificacoesConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/simulacoesconsulta',
    name: 'SimulacoesConsulta',
    component: () => import('../components/Negociacoes/Simulacoes/SimulacoesConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/reservasconsulta',
    name: 'ReservasConsulta',
    component: () => import('../components/Negociacoes/Reservas/ReservasConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/propostasconsulta',
    name: 'PropostasConsulta',
    component: () => import('../components/Negociacoes/Propostas/PropostasConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/configuracoes',
    name: 'Configuracoes',
    component: () => import('../components/Configuracoes/ConfiguracoesContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  /*
    {
      path: '/cam',
      name: 'Cam',
      component: () => import ('../components/ComponentesGlobais/WebCam/Cam.vue')
    },
  
    {
      path: '/camera',
      name: 'Camera',
      component: () => import ('../components/ComponentesGlobais/WebCam/Camera.vue')
    },
  
    {
      path: '/webcam',
      name: 'WebCam',
      component: () => import ('../components/ComponentesGlobais/WebCam/WebCam.vue')
    },
  
    {
      path: '/webcam2',
      name: 'WebCam2',
      component: () => import ('../components/ComponentesGlobais/WebCam/WebCam2.vue')
    },
  */
  {
    path: '/indicecadastro',
    name: 'IndiceCadastro',
    component: () => import('../components/CadastroBasico/IndiceCadastro.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  /*
    {
      path: '/financeirocontainer',
      name: 'FinanceiroContainer',
      component: () => import ('../components/Financeiro/FinanceiroContainer.vue'),
      beforeEnter: async (to, from, next) => {
        await Autenticado();
        if (store_usuario.autenticado) {
          var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
          if (ls_Found)
            next()
          else
            router.push('/');
        }
        else {
          router.push('/');
        }
      }
    },
  */
  {
    path: '/contasreceber',
    name: 'ContasReceber',
    component: () => import('../components/Financeiro/ContasReceber/ContasReceber.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/contaspagar',
    name: 'ContasPagar',
    component: () => import('../components/Financeiro/ContasPagar/ContasPagar.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/planocontas',
    name: 'PlanoContas',
    component: () => import('../components/Financeiro/PlanoContas/PlanoContas.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/centrocusto',
    name: 'CentroCusto',
    component: () => import('../components/Financeiro/CentroCusto/CentroCusto.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/cnabretorno',
    name: 'CnabRetornoContainer',
    component: () => import('../components/Financeiro/CnabRetorno/CnabRetornoContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/cnabremessa',
    name: 'CnabRemessa',
    component: () => import('../components/Financeiro/CnabRemessa/CnabRemessa.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  {
    path: '/conciliacaobancaria',
    name: 'ConciliacaoBancaria',
    component: () => import('../components/Financeiro/ConciliacaoBancaria/ConciliacaoBancaria.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },
  /*
    {
      path: '/painelgrid',
      name: 'PainelGrid',
      component: () => import ('../components/Paineis/PaineisGrid/PainelGrid.vue'),
      beforeEnter: async (to, from, next) => {
        await Autenticado();
        if (store_usuario.autenticado) {
          var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
          if (ls_Found)
            next()
          else
            router.push('/');
        }
        else {
          router.push('/');
        }
      }
    },
  
    {
      path: '/sgi_teste',
      name: 'SgiTeste',
      component: () => import ('../pages/SgiTeste.vue')   
    },
    ////++++
  */

  // {
  //   path: "/permissoes",
  //   name: "Permissoes",
  //   component: () =>
  //     import("../components/Configuracoes/Privilegios/Privilegios.vue"),
  //   beforeEnter: async (to, from, next) => {
  //     await Autenticado();
  //     if (store_usuario.autenticado) {
  //       var ls_Found = store_usuario.user.pessoa_funcao.find(
  //         (o) =>
  //           o.cod_funcao == 2 ||
  //           o.cod_funcao == 3 ||
  //           o.cod_funcao == 4 ||
  //           o.cod_funcao == 5
  //       );
  //       if (ls_Found) next();
  //       else router.push("/");
  //     } else {
  //       router.push("/");
  //     }
  //   },
  // },


  // {
  //   path: '/imoveisplanilha',
  //   name: 'ImoveisPlanilha',
  //   component: () => import ('../components/Imoveis/ImoveisPlanilha.vue'),
  //   beforeEnter: async (to, from, next) => {
  //     await Autenticado();
  //     if (store_usuario.autenticado) {
  //       var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 4 || o.cod_funcao == 5))
  //       if (ls_Found)
  //         next()
  //       else
  //         router.push('/');
  //     }
  //     else {
  //       router.push('/');
  //     }
  //   }
  // },

  // =============== ROTAS DO MODULO DE OBRAS =================================================

  //Rota Obra Consulta
  {
    path: "/obraconsulta/",
    name: 'ObraConsulta',
    component: () => import('../components/GestaoObras/Obra/ObraConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  //Rota Obra Container
  {
    path: "/obracontainer/:cod_obra",
    name: 'ObraContainer',
    component: () => import('../components/GestaoObras/Obra/ObraContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  //Rota Obra Container nova obra
  {
    path: "/obracontainer/",
    name: 'ObraContainerNovo',
    component: () => import('../components/GestaoObras/Obra/ObraContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },


  //Rota Insumo Consulta
  {
    path: "/insumoconsulta/",
    name: 'InsumoConsulta',
    component: () => import('../components/GestaoObras/Insumo/InsumoConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  //Rota Insumo Container
  {
    path: "/insumocontainer/:cod_insumo",
    name: 'InsumoContainer',
    component: () => import('../components/GestaoObras/Insumo/InsumoContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  //Rota Cotacao Consulta
  {
    path: "/cotacaoconsulta",
    name: 'CotacaoConsulta',
    component: () => import('../components/GestaoObras/Cotacao/CotacaoConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  // Rota Deposito Consulta
  {
    path: "/depositoconsulta",
    name: 'DepositoConsulta',
    component: () => import('../components/GestaoObras/Deposito/DepositoConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: "/depositocontainer/:cod_deposito",
    name: 'DepositoContainer',
    component: () => import('../components/GestaoObras/Deposito/DepositoContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },


  //Rota Cotação Container (criada por Rogério Chimionato em 12/8/24)
  // precisa verificar se terá que alterar o component
  {
    path: "/cotacaocontainer/:cod_cotacao",
    name: 'CotacaoContainer',
    component: () => import('../components/GestaoObras/Cotacao/CotacaoContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  //Rota Obra Container nova obra
  {
    path: "/cotacaocontainer/",
    name: 'CotacaoContainerNovo',
    component: () => import('../components/GestaoObras/Cotacao/CotacaoContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: "/compraconsulta/",
    name: 'CompraConsulta',
    component: () => import('../components/GestaoObras/OrdemCompra/OrdemCompraConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 7))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/obralista2',
    name: 'ObraLista2',
    component: () => import('../components/GestaoObras/Cotacao/Tabela/ObraLista2.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  // =============== ROTAS DO MODULO DE IMOVEIS =================================================

  {
    path: '/imovelconsulta',
    name: 'ImovelConsulta',
    component: () => import('../components/GestaoImoveis/Imovel/ImovelConsulta.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },

  {
    path: '/imovelcontainer/:cod_imovel',
    name: 'ImovelContainer',
    component: () => import('../components/GestaoImoveis/Imovel/ImovelContainer.vue'),
    beforeEnter: async (to, from, next) => {
      await Autenticado();
      if (store_usuario.autenticado) {
        var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))
        if (ls_Found)
          next()
        else
          router.push('/');
      }
      else {
        router.push('/');
      }
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'reload', // other options: 'ignore' and 'reject'
  meta: {
    reload: true,
  },
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  //return false
  //console.log('router.beforeEach', to);
  //console.log('store_usuario.autenticado', store_usuario.autenticado);
  // http://localhost:8080/contasreceber?ViscomSGI=true&token=eyJhbGciO.iJIUzI1R5cC.I6IkpXVCJ9iYW5j.b19kYWRvcyI6Im.RiX2ltb2JfZnJhb&cod_empresa=1&email=fernando@viscom.com.br
  // http://localhost:8080/contasreceber?ViscomSGI=true&token=eyJhbGciO.iJIUzI1R5cC.I6IkpXVCJ9iYW5j.b19kYWRvcyI6Im.RiX2ltb2JfZnJhb&cod_empresa=1&email=aguinaldo.diniz@parraimobiliaria.com.br
  if (to.query?.ViscomSGI && to.query?.ViscomSGI == 'true') {
    //console.log("to.query.email.toLowerCase()", to.query.email.toLowerCase());
    store_site.ViscomSGI = true;
    if (!store_usuario.autenticado) {
      const ls_login = await logIn({
        email: to.query.email.toLowerCase(),
        senha: C_SENHA_SGI,
        cod_empresa: to.query.cod_empresa
      });

      //console.log("ls_login:::", ls_login);
      if (ls_login == 'success') {
        //console.log('LOGOUUUUUUU');
        //console.log('NEXT1');
        next();
      }
      else {
        store_site.alert_cor = "#FF0000";
        store_site.alert_timeout = 10000;
        store_site.alert_msg = ls_login
        store_site.alert = true;
      }
    }
    else {
      //console.log('Logado !!');
      //console.log('NEXT2');
      next();
    }
  }
  else {
    //console.log('NEXT3');
    next();
  }
  //   store_site.ViscomSGI = false;
  //console.log('store_site.ViscomSGI', store_site.ViscomSGI);
})

export default router
