import store_usuario from "../store/store_usuario";
import store_site from "../store/store_site"
////++++import store_ModalNegociacao from "../components/ModalNegociacao/store_ModalNegociacao"
import { MenuPrincipalGet, DestroyStores } from "../services/global"
import { API, API_MUDAR, padrao_baseURL_API, BASE_URL_WEBSOCKET } from "./API";
import url from 'url';

// Tenta logar no banco de dados Padrão
const logIn = async (body) => {
    const resp = await API.post(`login`, JSON.stringify(body));
    var { data } = resp;

    if (data.message === "success") {

        // Altera a conexao para o banco de dados do cliente, daí em diante passa a operação no Back do cliente
        const myURL = url.parse(data.result.url_api);
        API_MUDAR(myURL.protocol, myURL.hostname, myURL.port);

        // Tenta logar no banco de dados do Cliente
        const login_usuario = await API.post(`login_usuario`, {...data.result});
        data = login_usuario.data;

        // Grava as infomrações no cookie do Cliente
        store_usuario.user.foto     = null;
        store_usuario.user          = data.result;
        store_usuario.autenticado   = true;
        localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":user", JSON.stringify(store_usuario.user));
        localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":token", data.token);
        var ls_ResMenu              = await MenuItens();
        store_usuario.menu_itens    = ls_ResMenu;
        store_site.cod_empresa      = store_usuario.user.cod_empresa;
        return "success";
    }
    else
        return data.result;
};

const logOut = () => {
    store_usuario.user.foto     = null;
    store_usuario.user          = {};
    store_usuario.autenticado   = false;
    localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":user");
    localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
    ////++++store_ModalNegociacao.botoes = [];
    store_site.cod_empresa = null;
    DestroyStores();
    API_MUDAR();
                
    //console.log("logOut ===============")
    //return data.message;
    //history.push("/");
    //setSigned(false);
};

const validaToken = async () => {
    try {
        //console.log("validaToken 111")
        const resp = await API.post(`valida_token`);
        //console.log("validaToken 222", padrao_baseURL_API, resp)
        const { data } = resp;
        if (data.result.message === "success") {
            //console.log("validaToken++++++++++++SUCCESS", data.result.message)
            return data.result.message;
        }
        else {
            //console.log("validaToken++++++++++++ERROR", data.result)
            //logOut();
            return data.result;
        }
    }
    catch(err) {
        logOut();
        //console.log('ERRO =====', err);
    }
};

const Autenticado = async () => {
    let obj_token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
    let obj_user  = JSON.parse(localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":user"));

    if (!obj_token || !obj_user) {
        return false
    }
    else {
        // Valida o token que já existe no LocalStorage somente se o usuário já estiver logado
        // Token de envio já é capturado no header[x-access-token] antes de enviar
        let ls_ValidaToken = await validaToken ();
        const data = ls_ValidaToken;
        if (data === "success") {
            store_usuario.autenticado = true

            var ls_Found = store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5  || o.cod_funcao == 6  || o.cod_funcao == 7))
            if (ls_Found)
              store_usuario.tipo_cliente = "USUARIO"
            else
              store_usuario.tipo_cliente = "CLIENTE FINAL"
            var ls_ResMenu = await MenuItens();
            store_usuario.menu_itens = ls_ResMenu;
            //console.log(store_usuario.menu_itens)
            return true
        }
        else  {
            store_usuario.autenticado = false
            API_MUDAR();
            // console.log("NAO AUTENTICADO  =======");
            return false
        }
    }
}

// Busca os Menus que o Usuario possui privilégios dentro do banco de dados ---------------
const MenuItens = async () => {
    if (store_usuario.autenticado) {
        var la_Aux = []
        var la_ResMenu = []
        store_usuario.user.pessoa_funcao.map((value) => {
            la_Aux.push(value.cod_funcao)
        })

        var menuItems = await MenuPrincipalGet(store_site.cod_empresa);
        menuItems.map((value) => {
            const found = value.funcoes.some(r=> la_Aux.includes(r))
            if (found) {
                la_ResMenu.push(value);
            }
        })
        return la_ResMenu;
    }
    else
      return [];
}

// const GetEmpresaSite = async () => {
//     const resp = await API.post(`/site/get_empresa_site`);
//     const { data } = resp;
//     if (data.result.message === "success") {
//         return data.result.result;
//     }
//     else
//         return data.result;
// };

const UsuarioSitePost = async (body) => {
    const resp = await API.post(`/site/usuario`, JSON.stringify(body));
    const { data } = resp;
    store_usuario.user          = data.result;
    store_usuario.autenticado   = false;
    return data;
};

const UsuarioPost = async (body) => {
    const resp = await API.post(`/usuario`, JSON.stringify(body));
    const { data } = resp;
    return data;
};

const UsuarioPut = async (body) => {
    const resp = await API.put(`/usuario/${body.cod_usuario}`, JSON.stringify(body))
    const { data } = resp;
    return data;
};

const UsuarioSenhaPost = async (body) => {
    const resp = await API.post(`/usuario/redefinicao_senha_codigo`, JSON.stringify(body));
    const { data } = resp;
    if (data.message === "success") {
        store_usuario.user          = data.result;
        store_usuario.autenticado   = true;
        localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":user", JSON.stringify(store_usuario.user));
        localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":token", data.token);
    }
    return data;
};

const ReenviarCodigoSenhaPorEmail = async (body) => {
    const resp = await API.post(`/usuario/reenviar_codigo_senha_por_email`, JSON.stringify(body));
    const { data } = resp;
    if (data.message === "success") {
        //console.log(data);
    }
    return data;
};



export {
    logIn,
    logOut,
    validaToken,
    Autenticado,
    //GetEmpresaSite,
    UsuarioSitePost,
    UsuarioPost,
    UsuarioPut,
    UsuarioSenhaPost,
    ReenviarCodigoSenhaPorEmail,
    MenuItens
}
